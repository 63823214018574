@font-face {
    font-family: 'Lato';
    font-weight: 500;
    src: url('../../fonts/Lato-Regular.woff') format('woff');
} 

@font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: url('../../fonts/Lato-Bold.woff') format('woff');
} 

@font-face {
    font-family: 'Lato';
    font-weight: 400;
    src: url('../../fonts/Lato-Italic.woff') format('woff');
} 

@font-face {
    font-family: 'Calligraffiti';
    font-weight: 500;
    src: url('../../fonts/Calligraffiti.woff') format('woff');
} 