@use 'abstracts/colors';
@use 'abstracts/fonts';
@use 'abstracts/variables';

$debugSize : 0px;

.test {
    background-color : colors.$main-color
}

.footer { 

    margin-left: 5em;

    @media screen and (max-width:variables.$mobil-max-width) { 
        margin-left: 1em;
    }

    .section {

        .footerColumn {
            max-width: 40%;
            float: left;
            margin: 1em 2em;
            padding: 1em 0.5em;

            @media screen and (max-width:variables.$mobil-max-width) { 
                max-width: 80%;
                margin-bottom: 0;
                padding-bottom: 0;
                margin-top: 0;
                padding-top: 0;
            }
    
        }

        div {
            margin: 0.5em 0.2em;
            padding: 0.1em 0.5em;

            a {
                text-decoration: none;
            }
        
            a:visited, 
            a:active, 
            a:link {
                color: colors.$link-color;
            }  
            
            a:hover, 
            a:focus {
                text-decoration: underline;	
                color: colors.$link-color;
            }
        
        }
    }

    button {
        margin-right: 10px;
    }
        
    .tlf_prefix {
        color : colors.$prefix-color;
    }

}

.body {
    
    + * {
        padding: 0;
        margin: 0;
    }

    b {
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        letter-spacing: 0.07em;
    }

    i {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        letter-spacing: 0.07em;
    }
    
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    letter-spacing: 0.07em;

    /*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    align-content: center;

    h1, h2, h3 {
        color: colors.$header-color;
        text-wrap: balance;
    }

    h1 {
        text-align: center;
        padding: 0.1em;
        margin: 0.1em;
        line-height: 1.1;
        font-family: Calligraffiti;
        font-weight: 500;
    }

    :is(h2, h3) {
        text-align: left;
        border-bottom: 1px solid colors.$link-color;
        padding: 0.15em 0em;
        margin: 0.2em 0;
        line-height: 1.1;
    }

    .calif {
        font-family: Calligraffiti;
        font-weight: 500;
    }

    .content {
        width: 93%;
        padding: 0.2em 2%;
        margin: 0.2em 2%;
        align-items: center;

        @media screen and (max-width:variables.$mobil-max-width) {
            padding: 0.1em 2%;
            margin: 0.1em 2%;
            width: 93%;
        }

        .headlines {
            font-size: 1.5rem;
            text-align: center;
        }

        .profilarea {
            float: left;        
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1em;
            margin-top: 1em;
            height: auto;
            padding: 0em;
            border: $debugSize solid greenyellow;        
    
            .profil {
                float:left;
                width : 65%;
                border: $debugSize solid blueviolet;
                height: auto;
    
                @media screen and (max-width:variables.$mobil-max-width) { 
                    width: 95%;
                }

                span {
                    height: auto;
                    display: block;
                    padding: 0 0 0.85rem 0;
                    max-width: 65rem;
                }
    
            }
    
            .images {
                margin-left: 0.7em;
                margin-right: 0.7em;
                margin-bottom: 0.3em;
                float:left;
                width : 20%;
                height : auto;
        
                img  {
                    max-width : 250px;
                    height: auto;
                    margin-bottom: 0.3em;
                    border-radius: 25%;
        /*      border:2px red solid ; */
               }
            }
    
        }
    
        .profilDescription {
            height : auto;
            padding: 0.1em 0.1em;
    
            .competance {
                float: left;
                height: auto;
                width: 65%;

                @media screen and (max-width:variables.$mobil-max-width) { 
                    width: 85%;
                }

                li {
                    margin : .2em 0.2em;
                    padding: 0.1em 0.1em;
                }
            }
    
            .corecompetance {
                float: left;
                height: auto;
                width: 30%;
                margin-left: 1.7em;

                @media screen and (max-width:variables.$mobil-max-width) { 
                    width: 75%;
                }
    
                p {
                    margin: 0.7em 0.3em 0.5em 2.2em;
                    font-style: oblique;
                    text-decoration: solid;
                    font-weight: bold;
                    font-size: 1.1em;
                }

                ul {
                    list-style-type: none;

                    li {
                        -moz-osx-font-smoothing: grayscale;
                        font-style: normal;
                        font-variant: normal;
                        font-weight: normal;
                        letter-spacing: 0;
                        line-height: 1;
                        text-transform: none;
                        margin: 0.2em 0;
                        padding: 0.4em 0;
                    }

                }
    
            }
    
        }
    
        .cvinfo {
            width: 85%;
            float: left;       
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
            margin-top: 1rem;
            padding-left: 0;
            height: auto;
            border: $debugSize solid greenyellow;

            .activity {
                padding: 0.5em 0.5em 0.5em 0.5em;
                margin: 0.5em 0.5em 1.5em 0;
                background-color: rgb(245, 245, 245);
                border-radius: 5px;

                .title {
                    font-weight: bold;
                    float: left;
                    padding-right: 10px;
                }

                .company {
                    float: left;

                    i {
/*                        font-family: Calligraffiti;
                        font-weight: 500; */
                        letter-spacing: 0.08rem;
                    }

                }

                .period {
                    float: right;
/*                    text-transform: uppercase; */
                }

                .tools {
                    margin-top: 5px;
                    font-style: italic;
                }
            }
        
            @media screen and (max-width:variables.$mobil-max-width) {
                width: 95%;    
            }
        
        }
        
        .project {
            width: 85%;
            margin: 0.2em 0.2em 1.2em 0;
            padding: 0.5em 0.5em 0.5em 0.5em;
            border: solid $debugSize blue;
            background-color: rgb(245, 245, 245);;
            border-radius: 5px;

            @media screen and (max-width:variables.$mobil-max-width) { 
                width: 92%;
            }

            .title {
                float: left;
                font-weight: 900;
            }

            .periode {
                float: right;
            }

            .description {
                padding: 0.1em 0;
                margin: 0.1em 0;
                height: auto;
            }

            .tools, .role, .result {
                .label {
                    font-style: italic;
                }
            }
        }

        .competenceGrid {
    
            display: grid;
            gap: 1.5rem;
            grid-template-columns : repeat(2, 1fr);
            padding: 10px; 

            .competanceList {

                margin: 2% 5% ;
                padding: 0.2em 0;

                table {
                    padding: 0;
                    margin: 0;

                    th {
                        text-align: left;
                        padding: 0;
                        margin: 0;
                    }

                    tr {
                        margin: 0;
                        padding: 0;

                        td {
                            padding: 6px 3px 6px 0;
                            margin-left: 0;
                            margin-right: 5px;
                            span {
                                padding-right: 0px;
                            }
                        }
                    }
                }

                div {

                    div {
                        margin: 0 0 0.1em 0;
                        span {
                            padding: 0 0 0 0.7em;
                        }

                    }

                    div:last-child {
                        padding: 0 0 0.4em 0;
                    }
                }

                .course {
                    margin-bottom: 3rem;

                    @media screen and (max-width:variables.$mobil-max-width) { 
                        margin-bottom: 0.5rem;
                    }

                }

                

            }

            @media screen and (max-width:variables.$mobil-max-width) {
                display: inline;
                width: 75%;
            }
    
        }

        .personalInfo {
            float: left;        
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0.1em;
            margin-top: 0.1em;
            height: auto;
            padding: 0.2em 0.2em 0.2em 0.2em;
            border: $debugSize solid greenyellow;
            width: 85%;
            .filter button {
                border-radius: 5px;
                margin-left: 10px;
                height: 25px;
                background-color:white;
                border-style: solid;
                border-width: 2px;
                
            }
            button:hover,
            button:focus {
                background-color: colors.$button-hover;
            }

            .person {
                margin: 5px 5px 5px 0px; 
                float:left;
                width: 65%;
                border: $debugSize solid blueviolet;
                height: auto;

                div {
                    float:left;
                }

                .label {
                    width: 45%;
                }

                .languageSkills {
                    height: auto;
                    width: auto;

                    span {
                        padding: 0.2em 0.4em 0.2em 0;
                    }

                    .label {
                        width: 32%;
                        float: left;
                    }

                    .value {
                        width: auto;
                        float: left;
                    }
                }
            } 
        }
    
    }
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3em;
    background-color: colors.$nav-background;

    @media screen and (max-width:variables.$mobil-max-width) {
        height: 3.75em;
    }

    & a {
        padding: 0.1em 0.1em;
        margin: 0.1em 0.1em;
        text-decoration: none;

        :hover {
            opacity: 1;
        }

    }

    a:visited, 
    a:active, 
    a:link {
        color: colors.$link-color;
    }
    
    .title {

        font-weight: bold;

        @media screen and (max-width:variables.$mobil-max-width) {
            display: none;
        }
    
        .logo {
            position: absolute;
            top: 9px; 
            left: 8px;
            width: 42px;

            img {
                height: 35px;
                width: 35px;
            }
        }

        .titleText {
            position: absolute;
            top: 12px; 
            left: 45px;
        }
    }

    .titleMobil {
        display: none;

        @media screen and (max-width:variables.$mobil-max-width) {
            font-weight: bold;
            display: block;
        }
    }
    
    .languageShift {
        margin: 0;
        padding: 0;
        width: 70px;

        button {
            border: 0;
            background-color: colors.$nav-background;
            font-weight: 600;
            font-family: 'Lato', sans-serif;
            font-size: 0.775rem;
            opacity: 0.7;
            white-space: nowrap;
        }

        .activeLanguage {
            font-weight: 600;
            font-family: 'Lato', sans-serif;
            font-size: 0.775rem;
            white-space: nowrap;
            text-decoration: underline;
            margin: 0 0.25em 0 0;
            opacity: 1;
        }

        @media screen and (max-width:variables.$mobil-max-width) { 
            margin-right: 13%;
        }
    }

    /* Mobil menu : https://blog.logrocket.com/create-responsive-navbar-react-css/ */

    .navigation {
        position: relative;
        display: flex;
        align-items: center;
        color: black;
        box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    }

    .brand-name {
        text-decoration: none;
        color: black;
        font-size: 1.3rem;
        margin-left: 1rem;
    }

    .navigation-menu {
        margin-left: auto;

        a:hover,
        a:focus-visible {
            opacity: 1;
        }

        /* Hover Effect : https://codepen.io/mrkishi/pen/mdQZMRR  */
            /* https://www.youtube.com/watch?v=G_h2pGZcOzc */
        ul {
            --_gap: 1rem;
            display: flex;
            list-style-type: none;
            margin: 0;
            padding: 0 5rem 0 0;
            
            li {
                list-style-type: none;
                overflow: hidden;
                position: relative;
                padding-inline: calc(var(--_gap) / 6 );
                position : releative; 

                & a {
                    width: 100%;
                    opacity: 0.7;
                    text-transform: uppercase;
                    text-decoration: none;    
                
                    :hover {
                        opacity: 1;
                    }
                }

                :after {
                    content: '';
                    position: absolute;
                    inset-inline: 0;
                    inset-block-end: 0;
                    block-size: 3px;
                    background-color: orangered;
                    translate: var(--_translate, 0);
                    scale: var(--_scale, 0) 1;
                    transition:
                        scale 100ms var(--_scale-delay, 0ms), 
                        translate 200ms;
                }
            }
        }

        /* A previous sibling is hovered */
        li:hover ~ li {
            --_translate: -100%;
            --_scale-delay: 200ms;
        }

        /* Any other sibling is hovered */
        & :hover li {
            --_translate: 100%;
            --_scale-delay: 200ms;
        }

        /*
        The actually hovered <li>

        NOTE: same specificity as previous selector, so it must either come after or be artificially inflated (eg. :hover:hover)
        */
        li:hover {
            --_scale: 0.9;
            --_translate: 0;
            --_scale-delay: 0ms;
        }

    }

    .hamburger {
        border: 0;
        height: 40px;
        width: 40px;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: colors.$mobil-menu-bg-color;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        position: absolute;
        top: 4%;
        right: 1.5rem;
        transform: translateY(-50%);
        display: none;
    }

    @media screen and (max-width:variables.$mobil-max-width) {
        .hamburger {
            display: block;
        }

        .navigation-menu {
            ul {
                flex-direction: column;
                position: absolute;
                top: 2.75em;
                left: 0;
                width: 100%;
                height: calc(100vh - 60px);
                background-color: white;
                border-top: 1px solid black;
                display: none;

                li {
                    text-align: center;
                    margin: 0;
                
                    a {
                        width: 100%;
                        padding: 0.5rem 0;
                    }
                }
        
            } 
        }
 
        .navigation-menu li:hover,
        .navigation-menu li:focus {
            background-color: #eee;
        }

        .navigation-menu.expanded ul {
            display: block;
            height: auto;
        }
    }
}

/* standard settings */
body {

    color: black;

    padding: 0;
    margin: 0;

    line-height : 1.6;

    &:is( h1, h2, h3 ) {
        line-height: 1.1;
    }

    h1 {
        font-size: 3.1rem;
        font-family: Calligraffiti;
        font-weight: 500;
    }

    h2 {
        font-size: 2.3rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    p {
        min-height: 1.5em;
    }

    input, 
    button {
        margin-left: 0.1em;
        min-height: 1.7em;
    }

    button:hover, 
    button:focus {
        cursor: pointer;
    }

    .filter {
        margin: 5px 5px 5px 0;
        padding: 0.2em 0.2em 0.2em 0;

        button {
            border-radius: 8px;
            margin: 0.2em 0.5em 0.4em 0;
            padding: 0.2em 0.4em;
            background-color:white;
            border: 1px solid black;
            
            &:hover, 
            &:focus {
                background-color: colors.$button-hover;
            }
        }

    }

    .clear {
        height : 5px;
        border : $debugSize solid blueviolet;
        clear: both;
    }

}

html {
    scroll-behavior: smooth;

    .noshow {
        display: none;
    }
    
    /* Scrollbar Settings */

    /* width */
    ::-webkit-scrollbar {
        width: 1.00em;
        background-color: white;
        /* colors.$background; */
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius:  100vw;
        margin-block: 0.25em;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border: .2em solid #f1f1f1;
        border-radius:  100vw;
        margin-block: 0.5em;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

}
